import $ from 'jquery';
console.log('HandlerClient JS geladen');




document.addEventListener("DOMContentLoaded", function(){
    
    console.log(' --> DOMContentLoaded clientHandler');

    var body            = document.getElementsByTagName('body');

    clientHandler();
    browserDetection();
    

    function clientHandler(){

        var currentClient   = new MobileDetect(window.navigator.userAgent);

        console.log( 'currentClient.mobile() ' + currentClient.mobile() );          // 'Sony'
        console.log( 'currentClient.phone() ' + currentClient.phone() );           // 'Sony'
        console.log( 'currentClient.tablet() ' + currentClient.tablet() );          // null
        console.log( 'currentClient.userAgent ' + currentClient.userAgent() );       // 'Safari' 'Chrome'
        console.log( 'currentClient.userAgents ' + currentClient.userAgents() );       // 'Safari' 'Chrome'
        console.log( 'currentClient.versionStr ' + currentClient.versionStr('Windows') );  // Windows version
        console.log( 'currentClient.os >>>>>>>> ' + currentClient.os() );              // 'AndroidOS'
        console.log( 'currentClient.is( iPhone ) ' + currentClient.is('iPhone') );      // false
        console.log( 'currentClient.is( bot ) ' + currentClient.is('bot') );         // false
        console.log( 'currentClient.version( Webkit ) ' + currentClient.version('Webkit') );         // 534.3
        console.log( 'currentClient.versionStr( Build ) ' + currentClient.versionStr('Build') );       // '4.1.A.0.562'
        console.log( 'currentClient.match( playstation|xbox ) ' + currentClient.match('playstation|xbox') ); // false
    
        if (!currentClient.tablet() == 'null' || currentClient.tablet() !== 'null' ){
            document.body.classList.add(currentClient.tablet());
        }else{
            document.body.classList.add('not-Tablet');
        }
    
        if (currentClient.is('iPhone') == true ){
            document.body.classList.add('iPhone');
        }else{
            document.body.classList.add('not-iPhone');
        }
    
        if (!currentClient.mobile() == 'null' || currentClient.mobile() !== 'null' ){
            if ( !currentClient.mobile() == 'null' || currentClient.mobile() !== 'iPad'){
                document.body.classList.add(currentClient.mobile());
            }
        }else{
            document.body.classList.add('not-Mobile');
        }
    
        if (!currentClient.os() == 'null' || currentClient.os() !== 'null' ){
            document.body.classList.add(currentClient.os());
        }else{
            document.body.classList.add('not-AndroidOS');
        }
    
        if (!currentClient.userAgent() == 'null' || currentClient.userAgent() !== 'null' ){
            document.body.classList.add(currentClient.userAgent());
        }else{
            document.body.classList.add('not-Safari');
        }
    };

    function browserDetection (){
        // Opera 8.0+
        var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== 'undefined';

        // Safari 3.0+ "[object HTMLElementConstructor]" 
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;

        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;

        // Chrome 1 - 71
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        // Blink engine detection
        var isBlink = (isChrome || isOpera) && !!window.CSS;

        if ( isOpera !== false ){ document.body.classList.add('Opera')};
        if ( isChrome !== false ){ document.body.classList.add('Chrome')};
        if ( isFirefox !== false ){ document.body.classList.add('Firefox')};
        if ( isSafari !== false ){ document.body.classList.add('Safari')};
        if ( isIE !== false ){ document.body.classList.add('IE')};
        if ( isEdge !== false ){ document.body.classList.add('Edge')};
        if ( isBlink !== false ){ document.body.classList.add('Blink')};

    };



});

let stateCheck = setInterval(() => {

    if (document.readyState === 'complete') {
      // document ready

      console.log('doc Ready');
      clearInterval(stateCheck);
      $('body').animate({opacity: 1.0 },500);

    }
  },500);
  


